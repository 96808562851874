import React, { Component } from 'react';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import '../globalStyles.css';
import Layout from '../components/Layout';

const Container404 = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  & img {
    width: 45%;
    margin: auto;
    margin-top: 40px;

    @media (max-width: 1620px) {
      width: 55%;
    }

    @media (max-width: 1350px) {
      width: 65%;
    }

    @media (max-width: 900px) {
      width: 80%;
    }

    @media (max-width: 550px) {
      width: 90%;
    }
  }

  & h1 {
    font-size: 60px;
    margin-bottom: 2%;
    margin-top: 0;
    font-weight: 700;

    @media (max-width: 750px) {
      font-size: 50px;
    }

    @media (max-width: 550px) {
      font-size: 35px;
    }
  }

  & p {
    font-size: 27px;
    font-weight: 500;
    padding-top: 1%;
    padding-bottom: 4%;
    max-width: 96%;
    margin: auto;
    white-space: pre-line;

    @media (max-width: 1350px) {
      font-size: 23px;
    }

    @media (max-width: 750px) {
      font-size: 18px;
      padding-bottom: 6%;
    }

    @media (max-width: 550px) {
      font-size: 15px;
      max-width: 90%;
      padding-bottom: 7%;
    }
  }

  & a {
    color: var(--color-black);
    font-size: 20px;
    text-decoration: none;
    transition: all ease 0.3s;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
    margin: auto;
    margin-bottom: 60px;

    @media (max-width: 1350px) {
      font-size: 18px;
    }

    @media (max-width: 750px) {
      font-size: 16px;
    }

    @media (max-width: 550px) {
      font-size: 14px;
    }
  }

  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }
`;
export default class NotFoundPage extends Component {
  static propTypes = {
    data: PropTypes.object,
    pageContext: PropTypes.object,
  };

  render() {
    const { data, pageContext } = this.props;

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout>
        <Helmet>
          <title>{`${data?.mdx?.frontmatter?.title} | presono`}</title>
          <meta
            name="description"
            content={data?.mdx?.frontmatter?.snippetText404}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.mdx?.frontmatter?.title} />
          <meta property="og:description" content={data?.mdx?.frontmatter?.snippetText404} />
          <meta name="twitter:title" content={data?.mdx?.frontmatter?.title} />
          <meta name="twitter:description" content={data?.mdx?.frontmatter?.snippetText404} />
        </Helmet>
        <Container404>
          <img src={data?.mdx?.frontmatter?.image404} alt={data?.mdx?.frontmatter?.altAttribute404} />
          <h1>{data?.mdx?.frontmatter?.headline404}</h1>
          <p>{data?.mdx?.frontmatter?.subtext404}</p>
          <Link to="/">{data?.mdx?.frontmatter?.linkNameHomepage}</Link>
        </Container404>
      </Layout>
    );
  }
}

export const query = graphql`
query notFoundPageQuery($locale: String!) {
  mdx(
    fields: { locale: { eq: $locale } },
    frontmatter: { layout: { eq: "notFoundPage" } }
  ) {
    frontmatter {
      title
      snippetText404
      headline404
      subtext404
      linkNameHomepage
      image404
      altAttribute404
    }
  }
}
`;
